import React, { createContext, Component } from "react"

import { silentAuth } from "../utils/auth"

export const AppContext = createContext({ user: null, loading: true })

export class AppProvider extends Component {
  state = {
    user: null,
    loading: true,
    updateUser: user => {
      this.setState({
        user,
      })
    },
    updateLoading: loading => {
      this.setState({
        loading,
      })
    },
  }

  componentDidMount() {
    silentAuth(userData => {
      this.state.updateUser(userData)
      this.state.updateLoading(false)
    })
  }

  render() {
    return (
      <AppContext.Provider value={this.state}>
        {this.props.children}
      </AppContext.Provider>
    )
  }
}
