import * as React from "react"

import styles from "./navbar.module.scss"

import { login, logout } from "../../utils/auth"
import { AppContext } from "../../contexts/app.context"

class Navbar extends React.Component {
  static contextType = AppContext

  state = {
    profileDropdownOpen: false,
  }

  handleLoginClick = () => {
    login()
  }

  handleLogoutClick = () => {
    logout()
  }

  handleProfileClick = () => {
    this.setState({
      profileDropdownOpen: !this.state.profileDropdownOpen,
    })
  }

  render() {
    return (
      <nav className={`${styles.nav}`}>
        <div className={`${styles.navLeft}`}>
          <a className={`${styles.navItem}`} href="/dashboard">
            Dashboard
          </a>
        </div>

        <div className={`${styles.navRight}`}>
          <ul className={`${styles.navList}`}>
            {this.context.user && (
              <>
                <li className={`${styles.navListItem}`}>
                  <a
                    onClick={this.handleProfileClick}
                    className={`${styles.navListItemLink}`}
                  >
                    <img
                      src={this.context.user.picture}
                      alt="User profile"
                      className={`${styles.profilePicture}`}
                    />
                  </a>
                  <div
                    className={`${styles.profileDropdown}`}
                    style={{
                      display: this.state.profileDropdownOpen
                        ? `block`
                        : `none`,
                    }}
                  >
                    <div className={`${styles.profileDropdownTop}`}>
                      <div className={`${styles.profileDropdownPicture}`}>
                        <img
                          src={this.context.user.picture}
                          alt="User profile"
                          className={`${styles.profilePicture}`}
                        />
                      </div>
                      <div className={`${styles.profileDropdownName}`}>
                        <p>{this.context.user.name}</p>
                      </div>
                    </div>
                    <ul className={`${styles.profileDropdownOptions}`}>
                      <li className={`${styles.divider}`} />
                      <li className={`${styles.profileDropdownOption}`}>
                        <a onClick={this.handleLogoutClick}>
                          <i className={`fas fa-sign-out-alt`} />
                          Log out
                        </a>
                      </li>
                    </ul>
                  </div>
                </li>
              </>
            )}

            {!this.context.user && (
              <>
                <li className={`${styles.navListItem}`}>
                  <a
                    onClick={this.handleLoginClick}
                    className={`${styles.navListItemLink}`}
                  >
                    <span>Login</span>
                  </a>
                </li>
              </>
            )}
          </ul>
        </div>
      </nav>
    )
  }
}

export default Navbar
