// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-account-js": () => import("../src/pages/account.js" /* webpackChunkName: "component---src-pages-account-js" */),
  "component---src-pages-callback-js": () => import("../src/pages/callback.js" /* webpackChunkName: "component---src-pages-callback-js" */),
  "component---src-pages-courses-toi-content-2-js": () => import("../src/pages/courses/toi/content/2.js" /* webpackChunkName: "component---src-pages-courses-toi-content-2-js" */),
  "component---src-pages-courses-toi-content-3-js": () => import("../src/pages/courses/toi/content/3.js" /* webpackChunkName: "component---src-pages-courses-toi-content-3-js" */),
  "component---src-pages-courses-toi-content-4-js": () => import("../src/pages/courses/toi/content/4.js" /* webpackChunkName: "component---src-pages-courses-toi-content-4-js" */),
  "component---src-pages-courses-toi-content-5-js": () => import("../src/pages/courses/toi/content/5.js" /* webpackChunkName: "component---src-pages-courses-toi-content-5-js" */),
  "component---src-pages-courses-toi-content-6-js": () => import("../src/pages/courses/toi/content/6.js" /* webpackChunkName: "component---src-pages-courses-toi-content-6-js" */),
  "component---src-pages-courses-toi-content-7-js": () => import("../src/pages/courses/toi/content/7.js" /* webpackChunkName: "component---src-pages-courses-toi-content-7-js" */),
  "component---src-pages-courses-toi-content-8-js": () => import("../src/pages/courses/toi/content/8.js" /* webpackChunkName: "component---src-pages-courses-toi-content-8-js" */),
  "component---src-pages-courses-toi-content-9-js": () => import("../src/pages/courses/toi/content/9.js" /* webpackChunkName: "component---src-pages-courses-toi-content-9-js" */),
  "component---src-pages-courses-toi-content-index-js": () => import("../src/pages/courses/toi/content/index.js" /* webpackChunkName: "component---src-pages-courses-toi-content-index-js" */),
  "component---src-pages-courses-toi-index-js": () => import("../src/pages/courses/toi/index.js" /* webpackChunkName: "component---src-pages-courses-toi-index-js" */),
  "component---src-pages-courses-toi-quiz-index-js": () => import("../src/pages/courses/toi/quiz/index.js" /* webpackChunkName: "component---src-pages-courses-toi-quiz-index-js" */),
  "component---src-pages-dashboard-index-js": () => import("../src/pages/dashboard/index.js" /* webpackChunkName: "component---src-pages-dashboard-index-js" */),
  "component---src-pages-index-js": () => import("../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */)
}

