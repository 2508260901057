import * as React from "react"

import styles from "./footer.module.scss"

class Footer extends React.Component {
  render() {
    return (
      <div className={`${styles.wrapper}`}>
        <hr />

        <div className={`${styles.container}`}>
          <div className={`${styles.grid}`}>
            <div className={`${styles.column}`}>
              <a href="/" className={`${styles.link}`}>
                <i className={`fas fa-graduation-cap`} />
                IF3 Courses
              </a>
              <div className={`${styles.description}`}>
                A unique and beautiful collection of UI elements that are all
                flexible and modular. building the website of your dreams.
              </div>
            </div>
          </div>

          <hr />

          <div className={`${styles.bottomNotes}`}>
            <div className={`${styles.copyright}`}>
              <p>
                <i className={`fas fa-copyright`} />
                2019
                <a
                  href="https://functionalfitness.sport"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  International Functional Fitness Federation
                </a>
                All rights reserved.
              </p>
            </div>

            <div className={`${styles.poweredBy}`}>
              Powered By
              <a
                href="https://www.cloudcorridor.com"
                target="_blank"
                rel="noopener noreferrer"
              >
                Cloud Corridor
              </a>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default Footer
