import auth0 from "auth0-js"
import { navigate } from "gatsby"
import { isBrowser } from "./browser"

const webAuthOptions = {
  domain: process.env.AUTH0_DOMAIN || process.env.GATSBY_AUTH0_DOMAIN,
  clientID: process.env.AUTH0_CLIENTID || process.env.GATSBY_AUTH0_CLIENTID,
  redirectUri: process.env.AUTH0_CALLBACK || process.env.GATSBY_AUTH0_CALLBACK,
  responseType: "token id_token",
  scope: "openid profile email",
}

console.log("Web auth options", webAuthOptions)

const auth = isBrowser() ? new auth0.WebAuth(webAuthOptions) : {}

const tokens = {
  accessToken: false,
  idToken: false,
  expiresAt: false,
}

let user = {}

export const isAuthenticated = () => {
  if (!isBrowser()) {
    return
  }

  return localStorage.getItem("isLoggedIn") === "true"
}

export const login = () => {
  if (!isBrowser()) {
    return
  }

  auth.authorize()
}

const setSession = (cb = () => {}) => (err, authResult) => {
  if (err) {
    navigate("/")
    cb()
    return
  }

  if (!(authResult && authResult.accessToken && authResult.idToken)) {
    return
  }

  tokens.accessToken = authResult.accessToken
  tokens.idToken = authResult.idToken
  tokens.expiresAt = authResult.expiresIn * 1000 + new Date().getTime()

  user = authResult.idTokenPayload

  localStorage.setItem("isLoggedIn", true)

  cb(user)
}

export const silentAuth = callback => {
  if (!isAuthenticated()) {
    return callback()
  }

  auth.checkSession({}, setSession(callback))
}

export const handleAuthentication = (cb = () => {}) => {
  if (!isBrowser()) {
    return
  }

  auth.parseHash(setSession(cb))
}

export const getProfile = () => {
  return user
}

export const logout = () => {
  localStorage.setItem("isLoggedIn", false)

  auth.logout({
    returnTo: document.location.origin,
  })
}
