import * as React from "react"

import Navbar from "../navbar/navbar"
import Footer from "../footer/footer"

import { AppContext } from "../../contexts/app.context"

import styles from "./main.module.scss"
import Loading from "../../shared/components/loading/loading"

class MainLayout extends React.Component {
  static contextType = AppContext

  render() {
    if (this.context.loading) {
      return <Loading />
    }

    return (
      <>
        <div className={`${styles.app}`}>
          <Navbar />

          {this.props.children}

          <Footer />
        </div>
      </>
    )
  }
}

export default MainLayout
