import React from "react"

import "@fortawesome/fontawesome-free/css/all.css"
import "./src/styles/global.scss"

import MainLayout from "./src/layout/main/main"
import { AppProvider } from "./src/contexts/app.context"

class SessionCheck extends React.Component {
  render() {
    return (
      <AppProvider>
        <MainLayout>{this.props.children}</MainLayout>
      </AppProvider>
    )
  }
}

export const wrapRootElement = ({ element }) => {
  return <SessionCheck>{element}</SessionCheck>
}
